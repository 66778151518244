module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-243QVX8YT1","UA-213377852-1","AW-10809603262"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://saaseo.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"SaaSEO公式ウェブサイト","description":"SEOやマーケティングを強くして、結果につながるサイトを作りたい方はぜひ我々にご相談ください。日本・海外IT領域の最前線で活躍をしているメンバーが、貴社のネット周りを改善致します。無料のオンライン相談、岡崎市周辺であれば対面でのご相談も可能です。","language":"ja","openGraph":{"type":"website","locale":"ja","url":"https://www.saaseo.dev","title":"岡崎市のホームページ制作会社【SaaSEO】集客力が強みのWeb制作会社","description":"SEOやマーケティングを強くして、結果につながるサイトを作りたい方はぜひ我々にご相談ください。日本・海外IT領域の最前線で活躍をしているメンバーが、貴社のネット周りを改善致します。無料のオンライン相談、岡崎市周辺であれば対面でのご相談も可能です。","site_name":"SaaSEO公式ウェブサイト","images":[{"url":"https://saaseo.dev/static/7609539758738dcdbf8cbca078f4cbde/e5f7f/work-tv.webp","width":800,"height":600,"alt":"岡崎市のホームページ制作会社【SaaSEO】集客力が強みのWeb制作会社 1"},{"url":"https://saaseo.dev/static/d880534c54990597b08081a51f9b916d/47255/Logo_SaaSEO_Black.webp","width":800,"height":600,"alt":"岡崎市のホームページ制作会社【SaaSEO】集客力が強みのWeb制作会社 2"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
