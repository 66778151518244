exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blazing-fast-js": () => import("./../../../src/pages/blazing-fast.js" /* webpackChunkName: "component---src-pages-blazing-fast-js" */),
  "component---src-pages-compliance-js": () => import("./../../../src/pages/compliance.js" /* webpackChunkName: "component---src-pages-compliance-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-first-timer-js": () => import("./../../../src/pages/first-timer.js" /* webpackChunkName: "component---src-pages-first-timer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-responsive-js": () => import("./../../../src/pages/responsive.js" /* webpackChunkName: "component---src-pages-responsive-js" */),
  "component---src-pages-saas-js": () => import("./../../../src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-subsidy-js": () => import("./../../../src/pages/subsidy.js" /* webpackChunkName: "component---src-pages-subsidy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

